#Contact {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	align-content: space-between;
	/* background-image: url(../../Images/Parmefond1.png);
	background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
	background-repeat: no-repeat;
	background-size: 100vw; */
	height: 500px;
	}
	@media screen and (max-width:1000px){
		#Contact{
			/* background-image: url(../../Images/Parmptm.png); */
		}

		.Colonne{
			display: none;
			visibility: hidden;
		}
	}

	@media screen and (min-width:1000px){
		#Bandeaucontact{
			width:80%;
		}
		
	}
	#Bandeaucontact {
		width: 95%;
		height: 95%;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
        text-align: center;
		align-items: center;
		background-color: rgb(255, 255, 255);
		border: 15px solid #AB809A;
		margin: 5%;
        font-size: 2rem;
		}
		@media screen and (min-width:1100px) {
			#Bandeaucontact{
				width: 50%;
			}
		}
		
			#Infocontact{
				height: 100%;
				background-color: rgb(255, 255, 255);
				border-radius: 15px;
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				justify-content: space-between;
				align-content: flex-start;
				align-items: flex-start;
				margin: 5%;

				}
				.Titrecontact{
					font-size: 3rem;
					font-weight: bold;
					text-align: center;
					color:#AB809A;
					}

			#Iconecontact{
				width: 100%;
				display: flex;
				justify-content: space-around;
				align-items: center;
			}

		


