#Prehome {
	display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: space-between;
    align-items: center;
	/* background-image: url(../../Images/Parmefond1.png); */
    /* background-repeat: no-repeat;
	background-size: 100vw; */
	}
	@media screen and (max-width:800px){
		#Prehome{
			/* background-image: url(../../Images/Parmpdt.png); */
		}
	}
	#Bandeauprehome{
		width: 60%;
		display: flex;
		justify-content: space-around;
		align-items: center;
		border: solid 15px #BBDDCC;
		}
		#Pict{
			flex: 1;
			margin-left: 10%;
			}

		#Infoprehome{
			width: 50%;
			height: 80%;
			flex: 1;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			justify-content: center;
			text-align: center;
			align-items: center;
			border-radius: 15px;
			margin: 5%;
			font-size: 2.5rem;
			}
			@media screen and (max-width:800px){
				#Bandeauprehome{
					text-align: center;
					flex-direction: column;
					align-items: center;
					width:100%
				}
				#Infoprehome{
					width: 100%
				}
				#Livraison{
					width: 100%;
					display: flex;
					justify-content: space-around;
					align-items: center;
				}
			}
			#Marque{
				font-size: 3rem;
				font-weight: bold;
				color: #BBDDCC;
				}
			#Livraison{
				width: 60%;
				display: flex;
				justify-content: space-around;
				align-items: center;
			}
				.Titrehome{
					font-size: 3rem;
					text-align: center;
					font-weight: bold;
					color:#BBDDCC;
				}
			#Iconeprehome{
				width: 50%;
				display: flex;
				justify-content: space-around;
				align-items: center;
			}
