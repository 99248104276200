#Backmenu{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: stretch;
	height: 800px;
	}
	@media screen and (max-width:1100px){
		#PGmenu{
			display: none;
			visibility: hidden;
		}
		#PDmenu{
			display: none;
			visibility: hidden;
		}
	}
	#PGmenu {
		flex: 1;
		align-self: auto;
		/* background-image: url('../../Images/Parmbet.png'); */
		background-repeat: no-repeat;
		background-position: top;
		}
		
	#MENU{   
		flex: 2;
		align-self: auto;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-content: flex-start;
		align-items: center;
		height: 100%;
		border: 15px solid #80A8AB;
		}
		.Titremenu{
			font-size: 3em;
			text-align: center;
			font-weight: bold;
			color:#80A8AB;
			}
		.Soustitremenu{
			font-size: 2em;
			text-align: center;
			font-weight: bold;
			color:#80A8AB;
			}
		.imgmenu{
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			align-items: center;
			text-align: center;
			}
		#Entêtemenu{
			flex:1;
			width:80%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;
			}
			

		#CHXbase{
			flex:1;
			width:80%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;
			}
			#Base{
				width:100%;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-between;
				align-content: flex-start;
				align-items: center;
				font-weight: bold;
				}

		#CHXpuree{
			flex:1;
			width:60%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;
			}

			#Purée{
				width:100%;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-around;
				align-content: flex-start;
				align-items: center;
				font-weight: bold;
				}
		#Prix{
			flex:0.3;
			width:60%;
			display: flex;
			align-items: center;
			justify-content: space-around;
			margin-bottom: 2%;
			}
			.Portion{
				width:100%;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-around;
				align-content: flex-start;
				align-items: center;
				font-weight: bold;
				}
		@media screen and (max-width:800px){
			#Entêtemenu{
				width: 90%;
			}
			#CHXpuree{
				width:95%;
			}
			#CHXbase{
				width:95%;
			}
			#Prix{
				width:95%;
			}
		}
	#PDmenu{
		flex: 1;
		align-self: auto;
		/* background-image: url('../../Images/Parmptm.png'); */
		background-repeat: no-repeat;
		background-position: top;
		}
