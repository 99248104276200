#Backevent{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: stretch;
	}
	@media screen and (min-width:1100px) {
		#Backevent{
			height: 900px;
		}
	}
	@media screen and (max-width:1100px){
		#PGevent{
			display: none;
			visibility: hidden;
		}
		#PDevent{
			display: none;
			visibility: hidden;
		}
	}
	#PGevent {
		flex: 1;
		align-self: auto;
		/* background-image: url('../../Images/Parmbet.png'); */
		background-repeat: no-repeat;
		background-position: top;
		}
	#Event {
		flex: 2;
		align-self: auto;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-content: flex-start;
		align-items: center;
		height: 100%;
		overflow: scroll;
		overflow-x: hidden;
		border: 15px solid #E28892;
		}
		#Event::-webkit-scrollbar {
			display: none;
		}
		@media screen and (max-width:1100px){
			#Event{
				flex-direction: column;
			}
		}
		@media screen and (max-width:1100px){
			#Event{
				justify-content: flex-start;
			}
		}
		#Privé{
			flex: 1;
			width: 80%;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			align-content: center;
			align-items: center;
			}
			#Conditions{
				flex: 1;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				align-content: space-around;
				}
				.STevent{
					font-size: 2em;
					text-align: center;
					font-weight: bold;
					}
		#Publique {	
			flex: 1;
			width: 80%;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			align-content: center;
			align-items: center;
			}
			#Planning{
				font-weight:bold;
			}
			#Liste{
				text-align: center;
				width: 90%;
			}

		.Titreevent{
			font-size: 3em;
			text-align: center;
			font-weight: bold;
			color:#E28892;
			}
		.Texte{
			font-size: 1.8em;
			align-content: space-around;
			text-align: justify;
			float: left;
			}
	#PDevent {
		flex: 1;
		align-self: auto;
		/* background-image: url('../../Images/Parmptm.png'); */
		background-repeat: no-repeat;
		background-position: top;
		}


