#Apropos{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: stretch;
	}
	@media screen and (min-width:1100px) {
		#Apropos{
			height: 900px;
		}
	}
	@media screen and (max-width:1100px){
		#PGapropos{
			display: none;
			visibility: hidden;
		}
		#PDapropos{
			display: none;
			visibility: hidden;
		}
	}
	#PGapropos {
		flex: 1;
		align-self: auto;
		/* background-image: url('../../Images/Parmbet.png'); */
		background-repeat: no-repeat;
		background-position: top;
	}
	#Info{
		flex: 2;
		align-self: auto;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-content: flex-start;
		align-items: center;
		height: 100%;
		overflow: scroll;
		overflow-x: hidden;
		border: 15px solid #367478;
		}
		#Info::-webkit-scrollbar {
			display: none;
		}
		@media screen and (max-width:800px){
			#Info{
				justify-content: flex-start;
			}
		}
		.Titreapropos{
			font-size: 3em;
			text-align: center;
			font-weight: bold;
			color:#367478;
		}
		#Leparmentier{
			width:80%;
			display: flex;
			flex-direction: column;
			align-items: center;
			}
			#Infoparm{
				width: 100%;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: flex-start;
				align-content: flex-start;
				align-items: center;
			}
			#Infoparm > .Texte {
				margin-left: 5%;
			}
			@media screen and (min-width:1100px){
				#Compo > img{
					width: 60%;
				}
			}
			@media screen and (max-width:1100px){
				#Infoparm{
					flex-direction: column;
				}
				#Compo > img{
					width: 80%;
				}
			}
			@media screen and (max-width:800px){
				#Infoparm{
					justify-content: flex-start;
				}
				#Compo > img{
					width: 95%;
				}
			}
				#Compo{
					text-align: center;
				}
		#Concept{
			width:70%;
			display: flex;
			flex-direction: column;
			align-items: center;
			}
			#IMGparm{
				display: flex;
				justify-content: space-between;
				}
				#IMGparm > img{
					border: 5px solid #367478;
				}

		#Produits{
			width:70%;
			display: flex;
			flex-direction: column;
			align-items: center;
			}
			#Infopdt{
				width:100%;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: flex-start;
				align-content: flex-start;
				align-items: center;
				}
				@media screen and (max-width:1100px){
					#Infopdt{
						flex-direction: column;
						align-items: center;
					}
					#PRD2{
						display:none;
					}
					#PRD1 > img{
						width: 100%;
					}
				}
				@media screen and (min-width:1100px){
					#PRD1{
						display:none;
					}
					#PRD2 > img{
						width: 180%;
					}
				}
				@media screen and (max-width:800px){
					#Infopdt{
						align-items: center;
					}
					#PRD2{
						display:none;
					}
					#PRD1 > img{
						width: 80%;
					}
				}
				#Infopdt > .Texte {
					margin-right: 5%;
				}
				#PRD {
					margin-right: 5%;
				}
		#Partenaires{
			width:100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			}
			#Infopart{
				width: 80%;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-around;
				align-content: space-around;
				align-items: center;
				}
				.Part>img{
					width: 80px;
				}
				.Texte{
				font-size: 1.8em;
				align-content: space-around;
				text-align: justify;
				float: left;
				}

	#PDapropos{
		flex: 1;
		align-self: auto;
		/* background-image: url('../../Images/Parmptm.png'); */
		background-repeat: no-repeat;
		background-position: top;
	}









