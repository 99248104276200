@font-face {
    font-family: 'Agency FB Font';
    src: local('Agency FB Font'), url('../../AgencyFBFont.ttf') format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

*
{
    font-family: Agency FB Font;
}


/*Définition du haeder*/
#TETE{
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: flex-end;
	align-content: flex-end;
    margin-bottom: 1rem;
    }
    .ZONE{
        background-color: rgb(255, 255, 255);
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-content: flex-start;
        align-items: center;
        }
        .ZONE:nth-child(1){
            font-size : bold;
            text-align: left;
            justify-content: flex-start;
            flex: 1 ;
            }
            #Bhome{
                color: black;
                text-align: left;
                font-size: 2.5rem;
                background:none;
                border: none;
            }
        .ZONE:nth-child(2){
            font-size : bold;
            text-align: center;
            justify-content: center;
            flex: 1;
            }
        .ZONE:nth-child(3){
            text-align: right;
            justify-content: flex-end;
            flex: 1;
            }
            .ZONE:nth-child(3)>img{
                margin-right: 0.5rem;
            }
            @media screen and (max-width:400px){
                #Bhome{
                    font-size: 0.9rem;
                }
                .ZONE:nth-child(2){
                    font-size: 0.8rem;
                }
            }

            @media screen and (max-width:800px) and (min-width:400px){
                #Bhome{
                    font-size: 1.3rem;
                }
                .ZONE:nth-child(2){
                    font-size: 1.2rem;
                }
            }

            @media screen and (min-width:800px){
                #Bhome{
                    font-size: 3rem;
                }
                .ZONE:nth-child(2){
                    font-size: 2rem;
                }
            }
#Bandeau_nav{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-bottom: 1rem;
    }
    .Menu{
        height:100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: center;
        }
        .Menu > img{
            margin-top: 1rem;
        }
        .Bouton{
            text-align: center;
            -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
            transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
            color: white;
            border: none;
            }

        .Bouton:hover {
            transform: scale(1.1, 1.1);
            transition-duration: 0.5s;
            }

        .Bouton:hover::after {
            opacity: 1;
            }
            .Menu:nth-child(1) > a >.Bouton{
                background-color:#BBDDCC;
                }
            .Menu:nth-child(2) > a >.Bouton{
                background-color:#80A8AB;
                } 
            .Menu:nth-child(3) > a >.Bouton{
                background-color:#367478;
                }
            .Menu:nth-child(4) > a >.Bouton{
                background-color:#E28892;
                }
            .Menu:nth-child(5) > a >.Bouton{
                background-color:#AB809A;
                }



