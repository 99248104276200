#Backmentions{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: stretch;
	}
	@media screen and (min-width:1100px) {
		#Backmentions{
			height: 900px;
		}
	}
	@media screen and (max-width:800px){
		#PGmention{
			display: none;
			visibility: hidden;
		}
		#PDmention{
			display: none;
			visibility: hidden;
		}
	}
	#PGmention {
		flex: 1;
		align-self: auto;
		background-image: url('../../Images/Parmbet.png');
		background-repeat: no-repeat;
		background-position: top;
		}
    #Bandeaumentions{
        overflow-y: scroll;
        flex: 2.5;
		align-self: auto;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-content: flex-start;
		align-items: center;
        }
        #Mentions{
            margin: 10%;
            justify-content: space-between;
            }
            #Titremention{
            font-size: 3em;
			text-align: center;
			font-weight: bold;
            }
            .STmention{
                font-size: 2.5em;
			    text-align: center;
			    font-weight: bold;
                }
                .STTmention{
                    font-size: 2em;
			        text-align: center;
			        font-weight: bold;
                    }




    #PDmention {
        flex: 1;
        align-self: auto;
        background-image: url('../../Images/Parmbet.png');
        background-repeat: no-repeat;
        background-position: top;
        }