#Backfooter{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    }
    #Footer{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: flex-start;
        align-items: flex-start;
        height: 5px;
    }
        .Foot:nth-child(1){
            flex:1;
            display:flex;
            text-align: right;
            justify-content: center;
            }
            .Logo>img{
                width:20%;
            }
        .Foot:nth-child(2){
            flex:1;
            text-align: center;
            text-justify:center;
            }
                #Bmentions{
                    color: black;
                    text-align: left;
                    font-size: 1rem;
                    background:none;
                    border: none;
                    }
        .Foot:nth-child(3){
            flex:1;
            text-align: center;
            text-justify: center;
            justify-content: center;
            display: flex;
            flex-wrap: nowrap;
            }
            #Copyright{
                text-align: center;
                color: black;
                text-align: left;
                font-size: 1rem;
                flex:1
            }
        .Foot:nth-child(4){
            flex:1;
            text-align: left;
            justify-content: center;
        }
    

